import './index.less';

import Vue from "vue";
import Component from "vue-class-component";
import { ChildDetailToThis } from '@/app/components';
import { getStepsIcon } from "@/core/utils";
import { thisCity_stepsList } from "@/core/utils/enum/thisCity";
import { finishMessage, hasRegister, submitMessage, tbFinishMessage, zdFinishMessage } from '@/config/message';
import { SaveRefChildGardenDto, zsRefChildGardenService } from '@/app/apiServices/zsRefChildGarden/public-api';
import { helperService } from '@/app/apiServices/helper/public-api';
import storage from '@/core/utils/storage';

@Component({
    components: { ChildDetailToThis }
})
export default class ConfirmInformationTs extends Vue {
    stepsList = thisCity_stepsList;
    activeSteps = 4;
    data = new SaveRefChildGardenDto();

    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    okOnClick() {
        this.$dialog.confirm({
            title: '提示',
            message: submitMessage,
        })
            .then(() => {
                const { childId }: any = storage.GET_RegistrationInfo();
                helperService.requestRate(childId).then((res: any) => {
                    if (res.data.success == true) {
                        this.saveData();
                    } else {
                        this.$toast({
                            message: res.data.message,
                            position: "top",
                            duration: 5000,
                        });
                    }
                });
            })
            .catch(() => {
                //close
            });
    }

    saveData() {
        zsRefChildGardenService
            .saveRefChildGardenPage(this.data)
            .then((res: any) => {
                if (res.verifyResult.success === true) {
                    let message = "";
                    switch (this.data.gradeCode) {
                        case 'SZNJ_tb':
                            message = tbFinishMessage;
                            break;
                        case 'SZNJ_xb':
                            message = finishMessage;
                            break;
                        default:
                            message = zdFinishMessage;
                            break;
                    }
                    this.$dialog
                        .alert({
                            message: message,
                        })
                        .then(() => {
                            //删除缓存当前幼儿id
                            storage.DELETE_ActiveChildId();
                            this.$router.push("/record");
                        });
                }
                else {
                    if (res.verifyResult.exceptionCode == "GOHOME") {
                        this.$dialog.alert({
                            message: hasRegister,
                        }).then(() => {
                            this.$router.push("/record");
                        });
                    }
                    else {
                        this.$dialog.alert({
                            message: res.verifyResult.message,
                        }).then(() => {
                            this.$router.push(`/thisCity/regChildGarden`);
                        });
                        // this.$toast({
                        //     message: res.verifyResult.message,
                        //     position: 'top'
                        // });
                    }
                }
            })
            .catch((err: any) => {
                this.$toast({
                    message: "操作异常",
                    position: "top",
                });
            });
    }

    //修改信息
    updateClick() {
        const { childId } = storage.GET_RegistrationInfo();
        this.$store.dispatch("ACTION_ActiveChildId", childId);
        this.$router.push("/entrance");
    }

    async viewLoad() {
        const refChildGarden = storage.GET_RegChildGarden();
        this.data = refChildGarden;
        this.data.enterGardenType = Number(refChildGarden.enterGardenType);
        this.data.childId = Number(refChildGarden.childId);
    }

    mounted(): void {
        this.viewLoad();
    }
}