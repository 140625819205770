import { IDataDictionaryDto } from "../../baseDto/dataDictionaryDto";
import { IVerifyResult } from "../../baseDto/resultDto";
import { IDictionary } from "../../dictionary/public-api";

export interface ICheckBabyInfoInput {
    childId: number,
    id: string;
    name: string;
    cardNumber: string;
    cardType: string;
    // hasSFZ: boolean;
    // sFZStartTime: Date;
    // sFZEndTime: Date;
    // gradeCode: string,
    encryptIdentity: string,
    // type: number | null,
    birthday?: Date
}

export class CheckBabyInfoInput implements ICheckBabyInfoInput {
    childId = 0;
    id = '';
    name = '';
    cardNumber = '';
    cardType = '';
    // hasSFZ = false;
    // sFZStartTime = new Date;
    // sFZEndTime = new Date;
    // gradeCode = "";
    encryptIdentity = "";
    // type = null;
    birthday = new Date;
}

export interface ICheckBabyInfoResult {
    data: CheckBabyInfoResultData,
    verifyResult: IVerifyResult
}

export class CheckBabyInfoResultData {
    childId = 0;
    name = '';
    cardNumber = '';
    cardType = '';
    birthday = new Date;
    childType = 0;
    gradeCode = "";
    hasReportRecord = false;
}

export interface ICheckBabyInfoPage {
    data: CheckBabyInfoPageDto,
    verifyResult: IVerifyResult
}

export class CheckBabyInfoPageDto {
    id = '';
    name = '';
    cardNumber = '';
    cardType = '';
    birthday = '';
    cardTypeSelect: IDictionary[] = [];
    showRegNumberInput = true;
    pageDisabled = false
}