import axiosHttpService from '@/core/utils/axios';
import { Toast } from 'vant';
import { CheckBabyInfoInput } from './public-api';

/**
 * 招生幼儿基本信息
 * */
export class HelperService {
    queryZZKCertData(childId: string, encryptIdentity: string, gradeCode: string, enterType?: number) {
        // return axiosHttpService.getAxiosByApiUrl()
        //     .request({
        //         method: "POST",
        //         url: "Helper/QueryZZKCertData",
        //         params: { childId, encryptIdentity, gradeCode, enterType }
        //     })
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
        });
        const axios = axiosHttpService.getAxiosByApiUrl().request({
            method: "POST",
            url: "Helper/QueryZZKCertData",
            params: { childId, encryptIdentity, gradeCode, enterType }
        })
        axios.then(function () {
            Toast.clear();
        }).catch((err: any) => {
            Toast.clear();
            Toast.fail(`网络异常，请稍后再试`);
        })
        return axios;
    }
    queryBDCCertData(childId: string) {
        return axiosHttpService.getAxiosByApiUrl()
            .request({
                method: "POST",
                url: "Helper/QueryBDCCertData",
                params: { childId }
            })
    }
    fileSave(formData: FormData) {
        Toast.loading({
            message: '上传中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
        });
        const axios = axiosHttpService.getAxiosByApiUrl().request({
            method: "POST",
            url: "Helper/FileSave",
            data: formData
        })
        axios.then(function () {
            Toast.clear();
        }).catch((err: any) => {
            Toast.clear();
            Toast.fail(`网络异常，请稍后再试`);
        })
        return axios;
    }
    getCheckBabyInfoPage(childId: number) {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
        });
        const axios = axiosHttpService.getAxiosByApiUrl()
            .request({
                method: "POST",
                url: "Helper/GetCheckBabyInfoPage",
                params: { childId: childId }
            })
        axios.then(function () {
            Toast.clear();
        }).catch((err: any) => {
            Toast.clear();
            Toast.fail(`网络异常，请稍后再试`);
        })
        return axios;
    }
    // deleteZSChildBaseInfo(childId: number) {
    //     Toast.loading({
    //         message: '加载中...',
    //         forbidClick: true,
    //         loadingType: 'spinner',
    //         duration: 0
    //     });
    //     const axios = axiosHttpService.getAxiosByApiUrl()
    //         .request({
    //             method: "DELETE",
    //             url: "Helper/DeleteZSChildBaseInfo",
    //             params: { childId: childId }
    //         })
    //     axios.then(function () {
    //         Toast.clear();
    //     }).catch((err: any) => {
    //         Toast.clear();
    //         Toast.fail(`网络异常，请稍后再试`);
    //     })
    //     return axios;
    // }
    checkBabyInfo(data: CheckBabyInfoInput) {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
        });
        const axios = axiosHttpService.getAxiosByApiUrl()
            .request({
                method: "POST",
                url: "Helper/CheckBabyInfo",
                params: data
            })
        axios.then(function () {
            Toast.clear();
        }).catch((err: any) => {
            Toast.clear();
            Toast.fail(`网络异常，请稍后再试`);
        })
        return axios;
    }
    requestRate(childId: string) {
        return axiosHttpService.getAxiosByApiUrl().get("Helper/RequestRate?childId=" + childId)
    }
}

export default new HelperService();